<template>
  <v-container class="mb-60">
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between mb-4">
          <div class="d-flex align-center">
            <img
              v-svg-inline
              class="icon primary--text mr-2"
              src="@/assets/images/icons/categories.svg"
              alt=""
            >

            <h2 class="font-weight-bold">
              {{ title || $t('Categories') }}
            </h2>
          </div>
          <router-link
            :to="url"
            class="text-decoration-none blue-grey--text darken-1"
          >
            {{ $t("Voir tous") }}
            <v-icon class="m-0 p-0">
              mdi-menu-right
            </v-icon>
          </router-link>
        </div>
      </v-col>
      <v-col
        v-for="(c, index) in $store.state.topCategories.slice(0, 8)"
        :key="c.id"
        cols="6"
        sm="6"
        md="4"
        lg="3"
      >
        <router-link
          :to="'/categories/' + c.slug || ''"
          class="text-decoration-none"
        >
          <base-card class="card-hover-shadow">
            <v-card-text>
              <div class="d-flex align-center">
                <!--
                <v-avatar tile>
                  <img
                    class="mr-3"
                    :src="n.img"
                    alt=""
                  >
                </v-avatar>
                -->
                <p class="font-weight-bold mb-0 text-truncate">
                  {{ c.name }}
                </p>
              </div>
            </v-card-text>
          </base-card>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import CardCart from '@/components/cartCard/CardCart'
  import { mapGetters, mapActions } from 'vuex'
  export default {
    components: {
      CardCart,
    },
    props: {
      title: { type: String, default: '' },
      url: { type: String, default: '/categories' },
    },
    computed: {
      ...mapGetters(['getProducts']),
    },
    data () {
      return {

      }
    },
    methods: {
      ...mapActions(['addCart', 'removeCart']),
    },
  }
</script>
<style lang="scss" scoped>
.top-categories-img {
  width: 385px;
  min-width: 100%;
  max-width: 100%;
}
</style>
