<template>
  <div class="">
    <!-- section-1  -->
    <div
      class="white "
      :class="{'mb-60': $vuetify.breakpoint.mdAndUp }"
    >
      <v-container
        class="py-0"
        :class="{'px-0': $vuetify.breakpoint.smAndDown }"
      >
        <v-row no-gutters>
          <v-col
            cols="12"
            xl="3"
            lg="3"
            class="pt-0 d-none d-lg-block "
          >
            <navbar />
          </v-col>
          <v-col
            cols="12"
            xl="9"
            lg="9"
          >
            <home-slider />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- section-2  -->
    <!--    <deals />-->
    <div
      class="container "
      :class="{'mb-60': $vuetify.breakpoint.mdAndUp }"
    >
      <products-slider
        :products="$store.state.flashSales || []"
        :title="$t('Ventes flash')"
        url="/flashsales"
        store-path="flashSales"
      >
        <template #icon>
          <img
            v-svg-inline
            class="icon  mr-2"
            src="@/assets/images/icons/light.svg"
            alt=""
          >
        </template>
      </products-slider>
    </div>
    <v-container
      v-if="$store.state.settings.ad1"
      class=""
      :class="{'mb-60': $store.state.settings.ad1 && $vuetify.breakpoint.mdAndUp }"
    >
      <div class="row">
        <v-col
          cols="12"
        >
          <banner :banner="$store.state.settings.ad1" />
        </v-col>
      </div>
    </v-container>
    <!-- section-3  -->
    <!--    <home-categories-box :title="$t('Top Categories')" />-->
    <div
      :class="{'mb-60': $store.state.newProducts.length && $vuetify.breakpoint.mdAndUp }"
    >
      <products-slider
        :products="$store.state.newProducts || []"
        :title="$t('Denier ajout')"
        url="/promotions/DernierAjout"
        store-path="newProducts"
      >
        <template #icon>
          <img
            v-svg-inline
            class="icon  mr-2"
            src="@/assets/images/icons/new-product-1.svg"
            alt=""
          >
        </template>
      </products-slider>
    </div>
    <v-container
      v-if="$store.state.settings.ad2"
      :class="{'mb-60': $store.state.settings.ad2 && $vuetify.breakpoint.mdAndUp }"
    >
      <div class="row">
        <!--        <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="4"
                >
                  <a href="#">
                    <v-img
                      contain
                      src="@/assets/images/banners/banner-1.png"
                    />
                  </a>
                </v-col>-->
        <v-col
          cols="12"
        >
          <banner :banner="$store.state.settings.ad2" />
        </v-col>
      </div>
    </v-container>
    <home-top-shops
      :title="$t('Boutiques en vedette')"
      url="/boutique_vedette"
      store-path="promoShops"
      :shops="$store.state.promoShops"
    />

    <!--    <v-container class="mb-60">
      <products-list
        :shops="$store.state.moreproducts || []"
        store-path="moreproducts"
        url="/search"
        :title="$t('Plus pour toi')"
      />
    </v-container>-->

    <v-container
      v-if="$store.state.settings.ad3"
      class="mb-60"
    >
      <div class="row">
        <!--        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="4"
        >
          <a href="#">
            <v-img
              contain
              src="@/assets/images/banners/banner-1.png"
            />
          </a>
        </v-col>-->
        <v-col
          cols="12"
        >
          <banner :banner="$store.state.settings.ad3" />
        </v-col>
      </div>
    </v-container>
    <!-- section-4  -->
    <!--    <v-container class="mb-60">
      <v-row>
        <v-col cols="12" lg="6" xl="6">
          <div class="d-flex justify-space-between align-center mb-4">
            <div class="d-flex align-center">
              <img
                v-svg-inline
                class="icon mr-2"
                src="@/assets/images/icons/ranking-1.svg"
                alt=""
              />

              <h2 class="font-weight-bold">Top Ratings</h2>
            </div>
            <a href="#" class="text-decoration-none blue-grey&#45;&#45;text darken-1">
              View all
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>
          <div>
            <base-card>
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="(n, index) in topRatings"
                    :key="index"
                    cols="6"
                    md="4"
                    lg="3"
                    xl="3"
                  >
                    <router-link to="/single-product">
                      <v-img
                        class="mb-4 card-overlay-item"
                        contain
                        :src="n.img"
                      />
                    </router-link>
                    <div class="d-flex align-center justify-center mb-1">
                      <v-rating
                        :value="4.5"
                        color="amber"
                        dense
                        half-increments
                        readonly
                        size="16"
                      />

                      <div class="grey&#45;&#45;text text&#45;&#45;darken-4 ml-1">(49)</div>
                    </div>
                    <router-link
                      to="/single-product"
                      class="text-decoration-none"
                    >
                      <h5 class="text-center mb-1 grey&#45;&#45;text text&#45;&#45;darken-3">
                        {{ n.title }}
                      </h5>
                      <h5 class="primary&#45;&#45;text text-center">
                        {{ n.price }}
                      </h5>
                    </router-link>
                  </v-col>
                </v-row>
              </v-card-text>
            </base-card>
          </div>
        </v-col>
        <v-col cols="12" lg="6" xl="6">
          <div class="d-flex justify-space-between align-center mb-4">
            <div class="d-flex align-center">
              <img
                v-svg-inline
                class="icon mr-2"
                src="@/assets/images/icons/Group.svg"
                alt=""
              />

              <h2 class="font-weight-bold">Featured Brands</h2>
            </div>
            <a href="#" class="text-decoration-none blue-grey&#45;&#45;text darken-1">
              View all
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>
          <div>
            <base-card>
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="(n, index) in featuredBrands"
                    :key="index"
                    cols="12"
                    sm="6"
                  >
                    <router-link to="/single-product">
                      <v-img
                        class="mb-4 card-overlay-item br-8"
                        cover
                        aspect-ratio="1.7"
                        :src="n.img"
                      />
                    </router-link>
                    <router-link
                      to="/single-product"
                      class="text-decoration-none"
                    >
                      <h5 class="grey&#45;&#45;text text&#45;&#45;darken-4">
                        {{ n.title }}
                      </h5>
                    </router-link>
                  </v-col>
                </v-row>
              </v-card-text>
            </base-card>
          </div>
        </v-col>
      </v-row>
    </v-container>-->

    <!-- section-5  -->
    <!--    <v-container class="mb-60">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center mb-4">
            <div class="d-flex align-center">
              <img
                v-svg-inline
                class="icon mr-2"
                src="@/assets/images/icons/new-product-1.svg"
                alt=""
              />

              <h2 class="font-weight-bold">New Arrivals</h2>
            </div>
            <a href="#" class="text-decoration-none blue-grey&#45;&#45;text darken-1">
              View all
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>
          <div>
            <base-card>
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="(n, index) in newArrivals"
                    :key="index"
                    cols="6"
                    md="4"
                    lg="2"
                    xl="2"
                  >
                    <router-link
                      to="/single-product"
                      class="text-decoration-none"
                    >
                      <v-img
                        class="mb-4 br-8 card-overlay-item"
                        contain
                        :src="n.img"
                      />

                      <h5 class="mb-1 grey&#45;&#45;text text&#45;&#45;darken-3">
                        {{ n.title }}
                      </h5>
                      <h5 class="primary&#45;&#45;text">
                        {{ n.price }}
                      </h5>
                    </router-link>
                  </v-col>
                </v-row>
              </v-card-text>
            </base-card>
          </div>
        </v-col>
      </v-row>
    </v-container>-->

    <!-- section-6  -->
    <!--    <v-container class="mb-60">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center mb-4">
            <div class="d-flex align-center">
              <img
                v-svg-inline
                class="icon mr-2"
                src="@/assets/images/icons/gift.svg"
                alt=""
              />

              <h2 class="font-weight-bold">Big Discounts</h2>
            </div>
            <a href="#" class="text-decoration-none blue-grey&#45;&#45;text darken-1">
              View all
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>
        </v-col>
        <v-col cols="12" class="px-0">
          <div>
            <VueSlickCarousel
              class="slick-secondary"
              :arrows="true"
              :dots="false"
              v-bind="slickSettingsThree"
            >
              <div v-for="(n, index) in bigDiscounts" :key="index" class="m-5">
                <v-col cols="12">
                  <base-card>
                    <v-card-text>
                      <router-link
                        to="/single-product"
                        class="text-decoration-none"
                      >
                        <v-img
                          class="card-overlay-item mb-2 br-8"
                          :src="n.img"
                        />
                      </router-link>
                      <router-link
                        to="/single-product"
                        class="text-decoration-none"
                      >
                        <h5 class="grey&#45;&#45;text text&#45;&#45;darken-4">
                          {{ n.title }}
                        </h5>
                      </router-link>
                      <div class="d-flex">
                        <h5 class="primary&#45;&#45;text me-2">$250</h5>
                        <h5
                          class="grey&#45;&#45;text text&#45;&#45;darken-1 text-decoration-line-through"
                        >
                          $250
                        </h5>
                      </div>
                    </v-card-text>
                  </base-card>
                </v-col>
              </div>
              <template #prevArrow="arrowOption">
                <v-btn
                  class="mx-2 slick-arrow prev slick-prev"
                  fab
                  dark
                  small
                  color="secondary"
                >
                  <v-icon dark>
                    {{ arrowOption }}
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <template #nextArrow="arrowOption">
                <v-btn
                  class="mx-2 slick-arrow next slick-next"
                  fab
                  dark
                  small
                  color="secondary"
                >
                  <v-icon dark>
                    {{ arrowOption }}
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </template>
            </VueSlickCarousel>
          </div>
        </v-col>
      </v-row>
    </v-container>-->

    <!-- section-7  -->
    <!--    <v-container class="mb-60">
      <v-row>
        <v-col cols="12" lg="3" xl="2">
          <base-card>
            <v-card-text>
              <div v-for="(brand, index) in brandsIcon" :key="index" class="">
                <v-btn
                  block
                  class="mb-3 text-capitalize py-5"
                  elevation="0"
                  color="grey lighten-2 "
                >
                  <v-avatar size="16" tile class="me-4">
                    <img :src="brand.image" alt="" />
                  </v-avatar>
                  <span class="font-600">{{ brand.name }}</span>
                </v-btn>
              </div>
              <div class="mb-60" />
              <v-btn
                class="text-capitalize py-5"
                block
                elevation="0"
                color="grey lighten-2 "
              >
                <span class="font-600">View All Brands</span>
              </v-btn>
            </v-card-text>
          </base-card>
        </v-col>
        <v-col cols="12" lg="9" xl="10">
          <div class="d-flex justify-space-between mb-5">
            <h2 class="font-weight-bold">Cars</h2>
            <a href="#" class="text-decoration-none blue-grey&#45;&#45;text darken-1">
              View all
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>

          <v-row>
            <v-col
              v-for="(item, index) in getProducts.slice(25, 32).map((n) => n)"
              :key="index"
              cols="12"
              sm="6"
              md="6"
              lg="4"
              xl="3"
            >
              <card-cart
                card-hover-shadow
                :content-img="item.img"
                :content-text="item.title"
                :counter="item.qty"
                class="m-5"
                @cartRemove="removeCart(item)"
                @cartAdd="addCart(item)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>-->

    <!-- section-8  -->
    <!--    <v-container class="mb-60">
      <v-row>
        <v-col cols="12" lg="3" xl="2">
          <base-card>
            <v-card-text>
              <v-tabs
                v-model="tab"
                background-color="transparent"
                color="black"
                class="brand-tabs mb-3"
              >
                <v-tab class="text-capitalize">
                  <h3 class="font-weight-bold">Brands</h3>
                </v-tab>
                <div class="d-flex align-center grey&#45;&#45;text text&#45;&#45;lighten-1">
                  |
                </div>
                <v-tab class="text-capitalize">
                  <h3 class="font-weight-bold">Shops</h3>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in 2" :key="item">
                  <div
                    v-for="(brand, index) in brandsIcon"
                    :key="index"
                    class=""
                  >
                    <v-btn
                      block
                      class="mb-3 text-capitalize py-5"
                      elevation="0"
                      color="grey lighten-2 "
                    >
                      <v-avatar size="16" tile class="me-4">
                        <img :src="brand.image" alt="" />
                      </v-avatar>
                      <span class="font-600">{{ brand.name }}</span>
                    </v-btn>
                  </div>
                  <div class="mb-60" />
                  <v-btn
                    class="text-capitalize py-5"
                    block
                    elevation="0"
                    color="grey lighten-2 "
                  >
                    <span class="font-600">View All Brands</span>
                  </v-btn>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </base-card>
        </v-col>
        <v-col cols="12" lg="9" xl="10">
          <div class="d-flex justify-space-between mb-5">
            <h2 class="font-weight-bold">Mobile Phones</h2>
            <a href="#" class="text-decoration-none blue-grey&#45;&#45;text darken-1">
              View all
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>

          <v-row>
            <v-col
              v-for="(item, index) in getProducts.slice(32, 40).map((n) => n)"
              :key="index"
              cols="12"
              sm="6"
              md="6"
              lg="4"
              xl="3"
            >
              <card-cart
                card-hover-shadow
                :content-img="item.img"
                :content-text="item.title"
                :counter="item.qty"
                :amount="item.amount"
                @cartRemove="removeCart(item)"
                @cartAdd="addCart(item)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>-->

    <!-- section-9  -->

    <!-- section-10  -->
    <!--    <v-container class="mb-60">
      <v-row>
        <v-col cols="12" lg="3" xl="2">
          <base-card>
            <v-card-text>
              <v-tabs
                v-model="tab"
                background-color="transparent"
                color="black"
                class="brand-tabs mb-3"
              >
                <v-tab class="text-capitalize">
                  <h3 class="font-weight-bold">Brands</h3>
                </v-tab>
                <div class="d-flex align-center grey&#45;&#45;text text&#45;&#45;lighten-1">
                  |
                </div>
                <v-tab class="text-capitalize">
                  <h3 class="font-weight-bold">Shops</h3>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in 2" :key="item">
                  <div
                    v-for="(brand, index) in brandsIcon"
                    :key="index"
                    class=""
                  >
                    <v-btn
                      block
                      class="mb-3 text-capitalize py-5"
                      elevation="0"
                      color="grey lighten-2 "
                    >
                      <v-avatar size="16" tile class="me-4">
                        <img :src="brand.image" alt="" />
                      </v-avatar>
                      <span class="font-600">{{ brand.name }}</span>
                    </v-btn>
                  </div>
                  <div class="mb-60" />
                  <v-btn
                    class="text-capitalize"
                    block
                    elevation="0"
                    color="grey lighten-2 "
                  >
                    <span class="font-600">View All Brands</span>
                  </v-btn>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </base-card>
        </v-col>
        <v-col cols="12" lg="9" xl="10">
          <div class="d-flex justify-space-between mb-5">
            <h2 class="font-weight-bold">Optics / Watch</h2>
            <a href="#" class="text-decoration-none blue-grey&#45;&#45;text darken-1">
              View all
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>

          <v-row>
            <v-col
              v-for="(item, index) in getProducts.slice(41, 48).map((n) => n)"
              :key="index"
              cols="12"
              sm="6"
              md="6"
              lg="4"
              xl="3"
            >
              <card-cart
                card-hover-shadow
                :content-img="item.img"
                :content-text="item.title"
                :counter="item.qty"
                :amount="item.amount"
                @cartRemove="removeCart(item)"
                @cartAdd="addCart(item)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>-->

    <!-- section-11  -->
    <!--    <home-categories-box />-->

    <!-- section-12  -->

    <!-- section-13  -->
    <!--    <v-container class="mb-60">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3" xl="3">
          <base-card card-hover-shadow class="py-48">
            <v-card-text class="d-flex align-center justify-center flex-column">
              <div
                class="py-5 avatar-64 d-flex flex-row justify-center align-center grey lighten-2 rounded-circle mb-5"
              >
                <img
                  v-svg-inline
                  class="icon secondary&#45;&#45;text text&#45;&#45;darken-1 mr-0"
                  src="@/assets/images/icons/truck.svg"
                  alt=""
                />
              </div>
              <h4 class="font-600 grey&#45;&#45;text text&#45;&#45;darken-4 mb-3">
                Worldwide Delivery
              </h4>
              <v-card-subtitle
                class="text-center grey&#45;&#45;text text&#45;&#45;darken-2 pt-0"
              >
                We offer competitive prices on our 100 million plus product any
                range.
              </v-card-subtitle>
            </v-card-text>
          </base-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="3">
          <base-card card-hover-shadow class="py-48">
            <v-card-text class="d-flex align-center justify-center flex-column">
              <div
                class="py-5 avatar-64 d-flex flex-row justify-center align-center grey lighten-2 rounded-circle mb-5"
              >
                <img
                  v-svg-inline
                  class="icon secondary&#45;&#45;text text&#45;&#45;darken-1 mr-0"
                  src="@/assets/images/icons/credit.svg"
                  alt=""
                />
              </div>
              <h4 class="font-600 grey&#45;&#45;text text&#45;&#45;darken-4 mb-3">
                Safe Payment
              </h4>
              <v-card-subtitle
                class="text-center grey&#45;&#45;text text&#45;&#45;darken-2 pt-0"
              >
                We offer competitive prices on our 100 million plus product any
                range.
              </v-card-subtitle>
            </v-card-text>
          </base-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="3">
          <base-card card-hover-shadow class="py-48">
            <v-card-text class="d-flex align-center justify-center flex-column">
              <div
                class="py-5 avatar-64 d-flex flex-row justify-center align-center grey lighten-2 rounded-circle mb-5"
              >
                <img
                  v-svg-inline
                  class="icon secondary&#45;&#45;text text&#45;&#45;darken-1 mr-0"
                  src="@/assets/images/icons/shield.svg"
                  alt=""
                />
              </div>
              <h4 class="font-600 grey&#45;&#45;text text&#45;&#45;darken-4 mb-3">
                Shop With Confidence
              </h4>
              <v-card-subtitle
                class="text-center grey&#45;&#45;text text&#45;&#45;darken-2 pt-0"
              >
                We offer competitive prices on our 100 million plus product any
                range.
              </v-card-subtitle>
            </v-card-text>
          </base-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="3">
          <base-card card-hover-shadow class="py-48">
            <v-card-text class="d-flex align-center justify-center flex-column">
              <div
                class="py-5 avatar-64 d-flex flex-row justify-center align-center grey lighten-2 rounded-circle mb-5"
              >
                <img
                  v-svg-inline
                  class="icon secondary&#45;&#45;text text&#45;&#45;darken-1 mr-0"
                  src="@/assets/images/icons/headphone.svg"
                  alt=""
                />
              </div>
              <h4 class="font-600 grey&#45;&#45;text text&#45;&#45;darken-4 mb-3">
                24/7 Support
              </h4>
              <v-card-subtitle
                class="text-center grey&#45;&#45;text text&#45;&#45;darken-2 pt-0"
              >
                We offer competitive prices on our 100 million plus product any
                range.
              </v-card-subtitle>
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>
    </v-container>-->
  </div>
</template>

<script>
  import Navbar from '@/views/home/navbar'
  import CardCart from '@/components/cartCard/CardCart'
  import deals from '@/components/deals/Deals'
  import { mapGetters, mapActions } from 'vuex'
  import HomeCategoriesBox from '../../components/categories/HomeCategoriesBox'
  import HomeTopShops from '../../components/shops/HomeTopShops'
  import HomeSlider from '../../components/sliders/HomeSlider.vue'
  import ProductsList from '../../components/products/ProductsList.vue'
  import ProductsSlider from '../../components/products/ProductsSlider.vue'
  import Banner from '../../components/Banner.vue'
  export default {
    // name: 'Home',
    components: {
      Banner,
      ProductsSlider,
      ProductsList,
      HomeSlider,
      HomeTopShops,
      HomeCategoriesBox,
      Navbar,
      CardCart,
      deals,
    },
    computed: {
      ...mapGetters(['getProducts']),
    },
    data () {
      return {
        slickSettings: {
          infinite: false,
          speed: 500,
          slidesToShow: 6,
          slidesToScroll: 6,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 2100,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 6,
                infinite: true,
              },
            },
            {
              breakpoint: 1900,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
              },
            },
            {
              breakpoint: 1800,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        },
        slickSettingsTwo: {
          infinite: false,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
          ],
        },
        slickSettingsThree: {
          infinite: false,
          speed: 500,
          slidesToShow: 6,
          slidesToScroll: 6,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 6,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 3,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ],
        },
        brandsIcon: [
          {
            name: 'TMW',
            image: require('@/assets/images/brands/v.png'),
          },
          {
            name: 'Zerrari',
            image: require('@/assets/images/brands/u.png'),
          },
          {
            name: 'Zord',
            image: require('@/assets/images/brands/v.png'),
          },
          {
            name: 'Gini',
            image: require('@/assets/images/brands/u.png'),
          },
          {
            name: 'Nesla',
            image: require('@/assets/images/brands/v.png'),
          },
        ],

        topRatings: [
          {
            img: require('@/assets/images/products/camera-1.png'),
            title: 'Camera',
            price: '$3,300',
          },
          {
            img: require('@/assets/images/products/shoes-2.png'),
            title: 'Shoe',
            price: '$400',
          },
          {
            img: require('@/assets/images/products/mobile-1.png'),
            title: 'Mobile',
            price: '$999',
          },
          {
            img: require('@/assets/images/products/watch-1.png'),
            title: 'Watch',
            price: '$600',
          },
        ],
        newArrivals: [
          {
            img: require('@/assets/images/products/imagegoggles.png'),
            title: 'Sunglass',
            price: '$150',
          },
          {
            img: require('@/assets/images/products/lipstick(2).png'),
            title: 'Lipstick',
            price: '$250',
          },
          {
            img: require('@/assets/images/products/lipstick(2).png'),
            title: 'Lipstick',
            price: '$250',
          },
          {
            img: require('@/assets/images/products/bgwatch.png'),
            title: 'Lipstick',
            price: '$250',
          },
          {
            img: require('@/assets/images/products/lipstick(4).png'),
            title: 'Green Plant',
            price: '$250',
          },
          {
            img: require('@/assets/images/products/lipstick(3).png'),
            title: 'Bonsai Tree',
            price: '$450',
          },
        ],
        bigDiscounts: [
          {
            img: require('@/assets/images/products/Electronics/7.beatsw3.png'),
            title: 'BenX 2020',
          },
          {
            img: require('@/assets/images/products/Electronics/9.SonyTV1080p.png'),
            title: 'Tony TV 1080p',
          },
          {
            img: require('@/assets/images/products/Electronics/10.SonyPS4.png'),
            title: 'Tony PS4',
          },
          {
            img: require('@/assets/images/products/Electronics/11.Netgear2020.png'),
            title: 'Setgearr 2020',
          },
          {
            img: require('@/assets/images/products/Electronics/12.SonyBGB.png'),
            title: 'Tony BGB',
          },
          {
            img: require('@/assets/images/products/Electronics/13.LGProducts.png'),
            title: 'RG Products',
          },
          {
            img: require('@/assets/images/products/Electronics/14.Panasonic2019.png'),
            title: 'Sanasonic 2019',
          },
          {
            img: require('@/assets/images/products/Electronics/15.DuneHD.png'),
            title: 'Zune HD',
          },
          {
            img: require('@/assets/images/products/Electronics/19.LGWashingMachine.png'),
            title: 'JK Washing Machine',
          },
        ],
        topCategories: [
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
          {
            img: require('@/assets/images/banners/category-2.png'),
          },
          {
            img: require('@/assets/images/banners/category-3.png'),
          },
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
          {
            img: require('@/assets/images/banners/category-2.png'),
          },
          {
            img: require('@/assets/images/banners/category-3.png'),
          },
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
        ],
        featuredBrands: [
          {
            img: require('@/assets/images/products/london-britches.png'),
            title: 'London Britches',
          },
          {
            img: require('@/assets/images/products/jimandjiko.png'),
            title: 'Jim & Jago',
          },
        ],
        categories: [
          {
            img: require('@/assets/images/products/Fashion/Clothes/1.SilverHighNeckSweater.png'),
            title: 'Tops',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/10.LandsJacket.png'),
            title: 'Jacket',
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/18.DragonRedWristWatch.png'),
            title: 'Smart Watch',
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/10.RayBanOcean.png'),
            title: 'Sunglasses',
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/3.BrownCap.png'),
            title: 'Cap',
          },
          {
            img: require('@/assets/images/products/Fashion/Shoes/1.NikeRed.png'),
            title: 'Nike Red',
          },
          {
            img: require('@/assets/images/products/Fashion/Shoes/5.NikeBlue.png'),
            title: 'Nike Silver',
          },
          {
            img: require('@/assets/images/products/Fashion/Jewellery/1.Heavy22ktGoldNecklaceSet.png'),
            title: 'Gold Necklace',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/3.NBLHoopHoodie.png'),
            title: 'NBL Hoodie',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/10.LandsJacket.png'),
            title: 'Jacket',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/1.SilverHighNeckSweater.png'),
            title: 'Tops',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/10.LandsJacket.png'),
            title: 'Jacket',
          },
        ],
        heroSlider: [
          {
            img: require('@/assets/images/products/apple-watch-0.png'),
            name: '50% Off For Your First Shopping',
          },
          {
            img: require('@/assets/images/products/apple-watch-0.png'),
            name: ' 20% Off For  First Electronics Purchase',
          },
          {
            img: require('@/assets/images/products/apple-watch-0.png'),
            name: 'Get 70% Off For  First  Grocery Purchase',
          },
        ],
        tab: null,
        items: ['Brands', 'Shops'],
      }
    },
    methods: {
      ...mapActions(['addCart', 'removeCart']),
    },
  }
</script>
<style lang="scss" scoped>
.top-categories-img {
  width: 385px;
  min-width: 100%;
  max-width: 100%;
}
</style>
