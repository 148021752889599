<template>
  <a :href="banner.url">
    <v-img
      contain
      lazy-src="@/assets/images/banners/banner-2.png"
      :src="banner.image || '@/assets/images/banners/banner-1.png'"
    />
  </a>
</template>

<script>

  export default {
    name: 'Banner',
    directives: {
    },
    components: {
    },
    props: {
      banner: {
        type: Object,
        default: () => {},
      },
      height: {
        type: Number,
        default: 300,
      },
    },
    data: () => ({
    }),
    computed: {

    },

    methods: {

    },
  }
</script>
