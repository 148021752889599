<template>
  <v-row v-if="items.length > 0 || loading">
    <v-col cols="12">
      <div class="d-flex justify-space-between mb-4">
        <div class="d-flex align-center">
          <slot name="icon">
            <img
              v-svg-inline
              class="icon  primary--text mr-2"
              src="@/assets/images/icons/categories.svg"
              alt=""
            >
          </slot>

          <h2 class="font-weight-bold">
            {{ title }}
          </h2>
        </div>
        <router-link
          :to="url"
          class="text-decoration-none blue-grey--text darken-1"
        >
          {{ $t("Voir tous") }}
          <v-icon class="m-0 p-0">
            mdi-menu-right
          </v-icon>
        </router-link>
      </div>
    </v-col>
    <v-col
      v-for="(item, index) in items"
      :key="index"
      cols="12"
      sm="4"
      md="4"
      lg="3"
      xl="2"
    >
      <template v-if="loading">
        <v-skeleton-loader
          type="image"
        />
        <v-skeleton-loader
          type="article"
          height="150"
        />
      </template>
      <card-cart
        v-else
        :content-img="item.img"
        :content-text="item.title"
        :counter="item.qty"
        class="m-5"
        :product="item"
        @cartRemove="removeCart(item)"
        @cartAdd="addCart(item)"
      />
    </v-col>
  </v-row>
</template>
<script>
  import CardCart from '@/components/cartCard/CardCart'
  import { mapActions, mapMutations } from 'vuex'
  export default {
    components: {
      CardCart,
    },
    props: {
      products: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
        default: '',
      },
      url: {
        type: String,
        default: '/',
      },
      storePath: {
        type: String,
        default: '',
      },
      total: {
        type: Number,
        default: 20,
      },

    },

    data () {
      return {
        items: [],
        loading: false,
        slickSettings: {
          infinite: false,
          speed: 500,
          slidesToShow: 6,
          slidesToScroll: 6,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 2100,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 6,
                infinite: true,
              },
            },
            {
              breakpoint: 1900,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
              },
            },
            {
              breakpoint: 1800,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        },
      }
    },
    computed: {

    },
    created () {
      if (!this.products.length) {
        this.getData()
      } else {
        this.items = this.products
      }
    },
    methods: {
      ...mapActions(['addCart', 'removeCart', 'fetchFlashSales']),
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`

        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      getData () {
        this.loading = true
        // eslint-disable-next-line no-undef
        axios.get(this.url, { params: { per_page: this.total } }).then((response) => {
          if (response.data) {
            if (response.data.data) {
              this.items = response.data.data.map((ele) => {
                return {
                  ...ele,
                  title: ele.name,
                  categoryName: ele.category ? ele.category.name : '',
                  amount: ele.price_with_tax,
                  original_price: ele.price_with_tax,
                  promo: ele.promo,
                  qty: 0,
                }
              })
            } else {
              this.items = response.data.map((ele) => {
                return {
                  ...ele,
                  title: ele.name,
                  categoryName: ele.category ? ele.category.name : '',
                  amount: ele.price_with_tax,
                  original_price: ele.price_with_tax,
                  promo: ele.promo,
                  qty: 0,
                }
              })
            }
            if (this.storePath.length) {
              this.setValue({ type: this.storePath, value: this.items })
            }
            for (var i = 0; i < this.items.length; i++) {
              this.pushItem({ type: 'products', field: 'id', value: this.items[i].id, object: this.items[i] })
            }
          }
        }).then(() => {
          this.loading = false
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.top-categories-img {
  width: 385px;
  min-width: 100%;
  max-width: 100%;
}
</style>
